





import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import __ from '@/shared/helpers/__'

@Component({
  methods: { __ }
})
export default class PlannedTimeFooter extends Vue {
  @Prop() form!: FormBase
  warningVisible: boolean = false

  created() {
    this.$watch('form.data', () => {
      this.warningVisible = (this.form.data.planned_hours || this.form.data.planned_minutes)
    }, { deep: true, immediate: true })
  }
}
