





































  import { Component, Prop, Vue } from 'vue-property-decorator'
  import PageHeader from '@/admin/components/PageHeader.vue'
  import __ from '@/shared/helpers/__'
  import Form from '@/shared/components/form/Form.vue'
  import FormBase from '@/shared/classes/form/form-base'
  import Task from '@/shared/modules/task/task.model'
  import Company from '@/shared/modules/company/company.model'
  import { CompanyGetters } from '@/shared/store/company/company.getters'
  import Field, { FieldSizes } from '@/shared/classes/form/field'
  import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
  import FormGroup, { FormGroupTypes } from '@/shared/classes/form/group'
  import ArrayField from '@/shared/classes/form/fields/array-field'
  import { FieldTypes } from '@/shared/components/form/field-types'
  import _ from 'lodash'
  import SelectField from '@/shared/classes/form/fields/Select/select-field'
  import { GlobalActions } from '@/shared/store/global/global.actions'
  import moment from 'moment'
  import TaskService from '@/shared/modules/task/task.service'
  import User from '@/shared/modules/user/user.model'
  import { AuthGetters } from '@/shared/store/auth/auth.getters'
  import { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
  import TaskTypeFilesDialog from '@/company/components/task-types/TaskTypeFilesDialog.vue'
  import { AxiosError } from 'axios'
  import AddFilesDialog from '@/company/components/tasks/add/AddFilesDialog.vue'
  import { getBase64 } from '@/shared/helpers/get-base64.helper'
  import PlannedTimeFooter from './PlannedTimeFooter.vue'
  import TaskTime from '@/shared/modules/task-time/task-time.model';

  @Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class ManagerAddTaskDialog extends Vue {
  @Prop() meta!: any
  static GROUP_DETAILS = 'details'
  static GROUP_PLANNED_TIME = 'planned-time'
  static GROUP_TASK_TYPES = 'task-types'
  static GROUP_MORE = 'more'
  static GROUP_COMMENTS = 'comments'
  static GROUP_ADDITIONAL = 'additional'
  static GROUP_MORE_ADDITIONAL = 'more-additional'

  forms: FormBase[] = []
  loading: boolean = false
  saved: FormBase[] = []
  taskTimesInitialValues: ArrayField = new ArrayField()
      .setKey('task_times')
      .setGroup(ManagerAddTaskDialog.GROUP_TASK_TYPES)
      .setDense(true)
      .setChildren([
        new Field()
            .setType(FieldTypes.checkbox)
            .setKey('is_other_type')
            .setTooltip(__('company.components.tasks.add-task-dialog.form.task-types.is-other-type'))
            .setSize(FieldSizes.oneTwelfth),
        new SearchableField()
            .setKey('task_type_uuid')
            .setTitle(__('company.components.tasks.add-task-dialog.form.task-types.uuid'))
            .loadItems({
              endpoint: `company/${ this.company.slug }/task-types`,
              value: 'uuid',
              title: 'name',
              perPage: 20,
              filters: [
                {
                  type: FilterOperators.nulled,
                  name: 'deleted_at',
                  value: true
                }
              ]
            })
            .setVisibleIf((values: any, index: number | null) =>
                !_.get(values, `task_times.${ index }.is_other_type`, true)
            )
            .setSize(FieldSizes.half),
        new Field()
            .setType(FieldTypes.textArea)
            .setRows(2)
            .setKey('other_name')
            .setTitle(__('company.components.tasks.add-task-dialog.form.task-types.other-name'))
            .setVisibleIf((values: any, index: number | null) =>
                _.get(values, `task_times.${ index }.is_other_type`, true)
            )
            .setSize(FieldSizes.half),
        new Field()
            .setType(FieldTypes.timePicker)
            .setKey('start_time')
            .setTitle(__('company.components.tasks.add-task-dialog.form.task-types.start-time'))
            .setSize(FieldSizes.twoHalfTwelfth),
        new Field()
            .setType(FieldTypes.timePicker)
            .setKey('end_time')
            .setTitle(__('company.components.tasks.add-task-dialog.form.task-types.end-time'))
            .setMeta({
              minKey: (fullKey: string) => `${ fullKey }.start_time`
            })
            .setSize(FieldSizes.twoHalfTwelfth),
        new Field()
            .setType(FieldTypes.custom)
            .setKey('photos')
            .setComponent(TaskTypeFilesDialog)
            .setValue([])
            .setSize(FieldSizes.oneTwelfth),
        new Field()
            .setType(FieldTypes.checkbox)
            .setKey('extra')
            .setTitle(__('company.components.tasks.add-task-dialog.form.task-types.extra'))
            .setSize(FieldSizes.oneTwelfth)
      ])

  created() {
    this.forms.push(this.createForm())
  }

  async submit() {
    let forms = _.cloneDeep(this.forms)
    this.loading = true
    forms = forms.filter((form: FormBase) => ! this.saved.some((item: FormBase) => item.formId === form.formId))

    let validationError: boolean = false

    await Promise.all(forms.map(async (form: FormBase, index: number) => {
      if (_.get(form.data, 'task_photos')) {
        await Promise.all(form.data.task_photos.map(async (taskPhoto: any, taskPhotoIndex: number) => {
          const base64 = await getBase64(taskPhoto.file)
          _.set(forms, `${ index }.data.task_photos.${ taskPhotoIndex }.file_name`, taskPhoto.file.name)
          _.set(forms, `${ index }.data.task_photos.${ taskPhotoIndex }.file`, base64)
        }))
      }

      await Promise.all(form.data.task_times.map(async (taskTime: any, taskTimeIndex: number) => {
        await Promise.all(taskTime.photos.map(async (photo: any, photoIndex: number) => {
          const base64 = await getBase64(photo.file)
          _.set(forms, `${ index }.data.task_times.${ taskTimeIndex }.photos.${ photoIndex }.file_name`, photo.file.name)
          _.set(forms, `${ index }.data.task_times.${ taskTimeIndex }.photos.${ photoIndex }.file`, base64)
        }))
      }))
    }))

    await Promise.all(forms.map(async (form: FormBase) => {
      const currentForm = this.forms.find((item: FormBase) => item.formId === form.formId)
      if (! currentForm) return
      const data = { ...form.injectValues, ...form.data }

      await TaskService.createValidation(this.company, data)
        .catch((error: AxiosError) => {
          currentForm.catchErrors(error)
          validationError = true
        })
    }))

    if (validationError) return this.loading = false

    const asyncFunctions: any = []

    forms.map((form: FormBase) => asyncFunctions.push(async () => await this.createTask(form)))

    for (let i = 0; i <= asyncFunctions.length - 1; i++) {
      await asyncFunctions[i]()
    }

    this.loading = false
    this.meta.onSuccess && this.meta.onSuccess()

    this.closeDialog()
  }

  async createTask(form: FormBase) {
    const currentForm = this.forms.find((item: FormBase) => item.formId === form.formId)
    if (! currentForm) return
    const data = { ...form.injectValues, ...form.data }

    return TaskService.create(this.company, data)
      .then(() => this.saved.push(currentForm))
  }

  addItem() {
    const latestIndex = this.forms.length - 1;
    this.forms.push(this.createForm({
      responsible_uuids: _.get(this.forms, `${ latestIndex }.data.responsible_uuids`),
      actual_date: _.get(this.forms, `${ latestIndex }.data.actual_date`),
    }))
  }

  deleteItem(index: number) {
    this.forms = this.forms.filter((form: FormBase, i: number) => i !== index)
  }

  createForm(initialValues: any = {}) {
    return new FormBase()
      .setEndpoint(`company/${ this.company.slug }/tasks`)
      .setModel(Task)
      .setGroups([
        new FormGroup()
          .setKey(ManagerAddTaskDialog.GROUP_DETAILS),
        new FormGroup()
          .setKey(ManagerAddTaskDialog.GROUP_PLANNED_TIME)
          .setType(FormGroupTypes.accordion)
          .setTitle(__('company.components.tasks.add-task-dialog.form.groups.planned-time'))
          .setChildren([
            new FormGroup()
              .setComponent(PlannedTimeFooter)
          ]),
        new FormGroup()
          .setKey(ManagerAddTaskDialog.GROUP_TASK_TYPES)
          .setTitle(__('company.components.tasks.add-task-dialog.form.groups.task-types')),
        new FormGroup()
          .setType(FormGroupTypes.accordion)
          .setKey(ManagerAddTaskDialog.GROUP_MORE)
          .setTitle(__('company.components.tasks.add-task-dialog.form.groups.more'))
          .setChildren([
            new FormGroup()
              .setKey(ManagerAddTaskDialog.GROUP_COMMENTS)
              .setTitle(__('company.components.tasks.add-task-dialog.form.groups.comments')),
            new FormGroup()
              .setKey(ManagerAddTaskDialog.GROUP_MORE_ADDITIONAL)
          ]),
        new FormGroup().setKey(ManagerAddTaskDialog.GROUP_ADDITIONAL)
      ])
      .setFields(this.generateFields())
      .setInjectValues({
        project_uuid: _.get(this.meta, 'project.uuid', null)
      })
      .setInitialValues({
        ...{
          responsible_uuids: [this.user.uuid],
          status_uuid: Task.STATUS_FINISHED,
          actual_date: moment().format('YYYY-MM-DD'),
          task_times: [{
            is_other_type: false,
            task_type_uuid: '',
            other_name: '',
            start_time: '',
            end_time: '',
            photos: [],
            extra: false
          }]
        },
        ...initialValues,
      })
      .setSubmit(false)
  }

  generateFields() {
    let fields: Field[] = [
      new SearchableField()
        .isMultiple()
        .setKey('responsible_uuids')
        .setTitle(__('company.components.tasks.add-task-dialog.form.worker'))
        .loadItems({ endpoint: `company/${ this.company.slug }/employees`, value: 'uuid', title: 'full_name', perPage: 20, filters: [
            {
              type: FilterOperators.equals,
              name: 'status.uuid',
              value: User.STATUS_ACTIVE
            }
        ] })
        .setGroup(ManagerAddTaskDialog.GROUP_DETAILS)
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.datePicker)
        .setKey('actual_date')
        .setTitle(__('company.components.tasks.add-task-dialog.form.actual.date'))
        .setGroup(ManagerAddTaskDialog.GROUP_DETAILS)
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.number)
        .setKey('planned_hours')
        .setTitle(__('company.components.tasks.add-task-dialog.form.planned.hours'))
        .setGroup(ManagerAddTaskDialog.GROUP_PLANNED_TIME)
        .setSize(FieldSizes.quarter),
      new Field()
        .setType(FieldTypes.number)
        .setKey('planned_minutes')
        .setTitle(__('company.components.tasks.add-task-dialog.form.planned.minutes'))
        .setGroup(ManagerAddTaskDialog.GROUP_PLANNED_TIME)
        .setSize(FieldSizes.quarter),
      new Field()
        .setType(FieldTypes.number)
        .setKey('planned_quantity')
        .setTitle(__('company.components.tasks.add-task-dialog.form.planned.quantity'))
        .setGroup(ManagerAddTaskDialog.GROUP_PLANNED_TIME)
        .setSize(FieldSizes.quarter),
      new SearchableField()
        .setKey('planned_unit_uuid')
        .setTitle(__('company.components.tasks.add-task-dialog.form.planned.measure'))
        .loadItems({ endpoint: '/units', value: 'uuid', title: 'name', perPage: 20 })
        .setGroup(ManagerAddTaskDialog.GROUP_PLANNED_TIME)
        .setSize(FieldSizes.twoHalfTwelfth),
      new Field()
          .setType(FieldTypes.custom)
          .setKey('photos')
          .setComponent(AddFilesDialog)
          .setValue([])
          .setGroup(ManagerAddTaskDialog.GROUP_PLANNED_TIME)
          .setSize(FieldSizes.oneTwelfth),
      this.taskTimesInitialValues,
      new ArrayField()
        .setKey('comments')
        .setGroup(ManagerAddTaskDialog.GROUP_COMMENTS)
        .setDense(true)
        .setInitialLength(0)
        .setChildren([
          new Field()
            .setKey('text')
            .setTitle(__('company.components.tasks.add-task-dialog.form.comments.comment'))
        ]),
      new Field()
        .setType(FieldTypes.textArea)
        .setKey('notes')
        .setGroup(ManagerAddTaskDialog.GROUP_MORE_ADDITIONAL)
        .setTitle(__('company.components.tasks.add-task-dialog.form.comment'))
        .setSize(FieldSizes.full),
      new SelectField()
        .setKey('status_uuid')
        .setTitle(__('company.components.tasks.add-task-dialog.form.status'))
        .loadItems({ endpoint: `statuses/model/${ Task.ALIAS }`, value: 'uuid', title: 'name' })
        .setGroup(ManagerAddTaskDialog.GROUP_ADDITIONAL)
        .setSize(FieldSizes.fourTwelfth),
      new Field()
        .setType(FieldTypes.checkbox)
        .setKey('send_by_email')
        .setMeta({
          class: 'd-flex align-center'
        })
        .setGroup(ManagerAddTaskDialog.GROUP_ADDITIONAL)
        .setSize(FieldSizes.eightTwelfth)
        .setTitle(__('company.components.tasks.add-task-dialog.form.send-by-email'))
    ]

    if (!this.meta.project) {
      fields.unshift(
        new SearchableField()
          .setKey('project_uuid')
          .setEntryKey('projectUuid')
          .setTitle(__('company.components.tasks.add-task-dialog.form.project'))
          .setGroup(ManagerAddTaskDialog.GROUP_DETAILS)
          .loadItems({
            endpoint: `company/${ this.company.slug }/projects`,
            value: 'uuid',
            title: 'name',
            perPage: 20,
            filters: [
              {
                type: FilterOperators.equals,
                name: 'status_uuid',
                value: 'project.active'
              }
            ]
          })
          .isRequired(),
      )
    }

    return fields
  }

  async closeDialog() {
    await this.$store.dispatch(GlobalActions.closeDialog)
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }
}
