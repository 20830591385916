













import { Component, Prop, Vue } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import Field from '@/shared/classes/form/field'
import __ from '@/shared/helpers/__'
import compressFile from '@/shared/helpers/compress-file.helper'

@Component({
  methods: { __ }
})
export default class AddMultiplePhotosButton extends Vue {
  @Prop() form!: FormBase
  @Prop() field!: Field

  addItems() {
    this.fileInput.$refs.input.click()
  }

  async handleOnChange(files: File[]) {
    let compressedFiles: any[] = []

    this.form.setLoading(true)
    await Promise.all(files.map(async (file: File) => {
      const compressedFile = await compressFile(file)

      compressedFiles.push({
        file: compressedFile,
      })
    }))
    this.form.setLoading(false)

    this.form.data.photos.push(...compressedFiles)
  }

  get fileInput(): any {
    return this.$refs.fileInput
  }
}
