import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableFilter, { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import __ from '@/shared/helpers/__'
import SelectItem from '@/shared/classes/form/fields/Select/select-item'
import moment from 'moment'
import { FieldSizes } from '@/shared/classes/form/field'

export default (table: DataTableBase, size: FieldSizes = FieldSizes.threeHalfTwelfth): DataTableFilter => new DataTableFilter()
  .setOperator(FilterOperators.equals)
  .setField(
    new SelectField()
      .setKey('date_range')
      .setTitle(__('company.views.projects.show.tabs.tasks-and-time.table.filters.period.title'))
      .setItems([
        new SelectItem()
          .setValue('today')
          .setTitle(__('company.views.projects.show.tabs.tasks-and-time.table.filters.period.items.today')),
        new SelectItem()
          .setValue('current_week')
          .setTitle(__('company.views.projects.show.tabs.tasks-and-time.table.filters.period.items.current-week')),
        new SelectItem()
          .setValue('last_week')
          .setTitle(__('company.views.projects.show.tabs.tasks-and-time.table.filters.period.items.last-week')),
        new SelectItem()
          .setValue('last_two_weeks')
          .setTitle(__('company.views.projects.show.tabs.tasks-and-time.table.filters.period.items.last-two-weeks')),
        new SelectItem()
          .setValue('current_month')
          .setTitle(__('company.views.projects.show.tabs.tasks-and-time.table.filters.period.items.current-month')),
        new SelectItem()
          .setValue('last_month')
          .setTitle(__('company.views.projects.show.tabs.tasks-and-time.table.filters.period.items.last-month'))
      ])
      .setBeforeChange((value: string) => {
        let from = null
        let to = null

        switch (value) {
          case 'today':
            from = moment().startOf('day')
            to = moment().endOf('day')
            break
          case 'current_week':
            from = moment().startOf('isoWeek')
            to = moment().endOf('isoWeek')
            break
          case 'last_week':
            from = moment().subtract(1, 'weeks').startOf('isoWeek')
            to = moment().subtract(1, 'weeks').endOf('isoWeek')
            break
          case 'last_two_weeks':
            from = moment().subtract(2, 'weeks').startOf('isoWeek')
            to = moment().subtract(1, 'weeks').endOf('isoWeek')
            break
          case 'current_month':
            from = moment().startOf('month')
            to = moment().endOf('month')
            break
          case 'last_month':
            from = moment().subtract(1, 'months').startOf('month')
            to = moment().subtract(1, 'months').endOf('month')
            break
        }
        if (! from || ! to) return

        const fromFieldIndex = table.filter.filters.findIndex((filter: DataTableFilter) => filter.getQueryName() === 'filter-gte-actual_date')
        const toFieldIndex = table.filter.filters.findIndex((filter: DataTableFilter) => filter.getQueryName() === 'filter-lte-actual_date')
        table.filter.filters[fromFieldIndex].field.value = from.format('YYYY-MM-DD')
        table.filter.filters[toFieldIndex].field.value = to.format('YYYY-MM-DD')
      })
      .setSize(size)
  )
