













import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import __ from '@/shared/helpers/__'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import Task from '@/shared/modules/task/task.model'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import DataTableTab from '@/shared/classes/components/data-table/data-table-tab'
import TaskTime from '@/shared/modules/task-time/task-time.model'
import DataTableHeaderTypes from '@/shared/classes/components/data-table/data-table-header-types'
import can from '@/shared/helpers/can.helper'
import permissions from '@/shared/helpers/permissions.helper'
import TaskService from '@/shared/modules/task/task.service'
import DataTableFilter, { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { RoleLevel } from '@/shared/modules/role/role.model'
import EmployeeAddTaskDialog from '@/company/components/tasks/add/EmployeeAddTaskDialog.vue'
import ManagerAddTaskDialog from '@/company/components/tasks/add/ManagerAddTaskDialog.vue'
import EmployeeShowTaskDialog from '@/company/components/tasks/show/EmployeeShowTaskDialog.vue'
import ManagerShowTaskDialog from '@/company/components/tasks/show/ManagerShowTaskDialog.vue'
import EmployeeEditTaskDialog from '@/company/components/tasks/edit/EmployeeEditTaskDialog.vue'
import ManagerEditTaskDialog from '@/company/components/tasks/edit/ManagerEditTaskDialog.vue'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import ShowAction from '@/shared/classes/components/data-table/default-actions/show-action'
import DayTotalHours from '@/company/components/tasks/index/DayTotalHours.vue'
import TaskConfirmedFilters from '@/company/components/tasks/index/TaskConfirmedFilters.vue'
import ConfirmedHeader from '@/company/components/tasks/ConfirmedHeader.vue'
import taskPeriodField from '@/company/helpers/taskPeriodField.helper'

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class TasksIndex extends Vue {
  table: DataTableBase = new DataTableBase()

  created() {
    this.table
      .setModel(Task)
      .setTabs([
        new DataTableTab()
          .setKey('all')
          .setTitle(__('company.views.projects.show.tabs.tasks-and-time.table.tabs.all')),
        new DataTableTab()
          .setKey('assigned')
          .setTitle(__('company.views.projects.show.tabs.tasks-and-time.table.tabs.assigned')),
        new DataTableTab()
          .setKey('unassigned')
          .setTitle(__('company.views.projects.show.tabs.tasks-and-time.table.tabs.unassigned')),
      ])
      .setTopRightComponent(TaskConfirmedFilters)
      .setPerPage(100)
      .setEndpoint(`company/${ this.company.slug }/tasks`)
      .setDoubleClick(this.editTask)
      .setHeaders([
        new DataTableHeader()
          .setKey('unique_id')
          .setEntryKey('uniqueId')
          .setSortable(false)
          .setText(__('company.views.projects.show.tabs.tasks-and-time.table.columns.id')),
        new DataTableHeader()
          .setKey('project.name')
          .setEntryKey('project.name')
          .setSortable(false)
          .setText(__('company.views.projects.show.tabs.tasks-and-time.table.columns.project')),
        new DataTableHeader()
          .setKey('responsible.full_name')
          .setEntryKey('responsible.fullName')
          .setSortable(false)
          .setText(__('company.views.projects.show.tabs.tasks-and-time.table.columns.to')),
        new DataTableHeader()
          .setKey('task_types')
          .setText(__('company.views.projects.show.tabs.tasks-and-time.table.columns.task-types'))
          .setSortable(false)
          .setValueParser((value: any, item: Task) => {
            return item.taskTimes.map((taskTime: TaskTime) => taskTime.realName).join(', ')
          }),
        new DataTableHeader()
          .setKey('actual_date')
          .setEntryKey('actualDate')
          .setSortable(false)
          .setText(__('company.views.projects.show.tabs.tasks-and-time.table.columns.start-date'))
          .setWidth('90px'),
        new DataTableHeader()
          .setType(DataTableHeaderTypes.hover)
          .setKey('actual_start_time')
          .setEntryKey('actualStartTime')
          .setSortable(false)
          .setValueParser((value: any, item: Task) => {
            if (! item.isTrackedApp) return { display: false }

            if (item.startLocationInProject) return {
              display: true,
              text: __('company.views.tasks.index.started-job-in-project-location'),
              color: 'green'
            }

            if (item.startLocationInProject == false) return {
              display: true,
              text: __('company.views.tasks.index.started-job-not-in-project-location'),
              color: 'red'
            }

            return { display: false }
          })
          .setText(__('company.views.projects.show.tabs.tasks-and-time.table.columns.actual-start-time')),
        new DataTableHeader()
          .setType(DataTableHeaderTypes.hover)
          .setKey('actual_end_time')
          .setEntryKey('actualEndTime')
          .setSortable(false)
          .setValueParser((value: any, item: Task) => {
            if (! item.isTrackedApp) return { display: false }

            if (item.endLocationGpsFailed) return {
              display: true,
              text: __('company.views.tasks.index.end-location-gps-failed'),
              color: 'gray'
            }

            if (item.endLocationInProject) return {
              display: true,
              text: __('company.views.tasks.index.ended-job-in-project-location'),
              color: 'green'
            }

            if (item.endLocationInProject == false) return {
              display: true,
              text: __('company.views.tasks.index.ended-job-not-in-project-location'),
              color: 'red'
            }

            if (item.actualStartTime && ! item.actualEndTime) return {
              display: true,
              text: __('company.views.tasks.index.tracking-in-progress'),
              color: 'gray',
              icon: 'mdi-clock'
            }

            return { display: false }
          })
          .setText(__('company.views.projects.show.tabs.tasks-and-time.table.columns.actual-end-time')),
        new DataTableHeader()
          .setKey('duration')
          .setText(__('company.views.projects.show.tabs.tasks-and-time.table.columns.hours-extra'))
          .setSortable(false)
          .setValueParser((value: any, item: Task) => {
            const { duration, extraHours, extraMinutes, extraDuration } = item
            value = `${ duration }`
            if (extraHours || extraMinutes ) value += ` / ${ extraDuration }`
            return value
          }),
        new DataTableHeader()
          .setType(DataTableHeaderTypes.hover)
          .setKey('all_duration')
          .setEntryKey('allDuration')
          .setSortable(false)
          .setValueParser((value: any, item: Task) => {
            if (! item.isTrackedApp) return { display: false }
            if (! item.timesNotInProject) return { display: false }
            if (item.timesNotInProject.length === 0) return { display: false }

            return {
              display: true,
              text: `${ __('company.views.tasks.index.not-in-project-location') } ${ item.timesNotInProject.join('; ') }`,
              color: 'red'
            }
          })
          .setText(__('company.views.projects.show.tabs.tasks-and-time.table.columns.all-duration')),
        new DataTableHeader()
          .setType(DataTableHeaderTypes.hover)
          .setKey('without_breaks')
          .setEntryKey('withoutBreaks')
          .setSortable(false)
          .setValueParser((value: any, item: Task) => {
            if (! item.isTrackedApp) return { display: false }
            if (! item.timesGpsFailed || item.timesGpsFailed.length === 0) return { display: false }

            return {
              display: true,
              text: `${ __('company.views.tasks.index.gps-failed') } ${ item.timesGpsFailed.join('; ') }`,
              color: 'gray'
            }
          })
          .setText(__('company.views.projects.show.tabs.tasks-and-time.table.columns.without-breaks')),
        new DataTableHeader()
          .setKey('notes')
          .setVisible(false)
          .setSortable(false)
          .setText(__('company.views.projects.show.tabs.tasks-and-time.table.columns.notes')),
        new DataTableHeader()
          .setType(DataTableHeaderTypes.imageWithText)
          .setKey('attachments_count')
          .setEntryKey('attachmentsCount')
          .setText(__('company.views.projects.show.tabs.tasks-and-time.table.columns.attachments-count'))
          .setClasses('d-flex justify-start align-center')
          .setMeta({
            icon: 'mdi-attachment mdi-rotate-90',
            width: 50,
            height: 30,
            imgKey: 'lastAttachment',
          })
          .setSortable(false),
        new DataTableHeader()
          .setType(DataTableHeaderTypes.count)
          .setKey('comments')
          .setText(__('company.views.projects.show.tabs.tasks-and-time.table.columns.comments-count'))
          .setMeta({
            icon: 'mdi-comment',
          })
          .setValueParser((value: any) => value.length)
          .setSortable(false),
        new DataTableHeader()
          .setType(DataTableHeaderTypes.checkbox)
          .setKey('confirmed')
          .setSortable(false)
          .setShowCondition(can([permissions.company.tasks.toggleConfirm]))
          .setText(__('company.views.projects.show.tabs.tasks-and-time.table.columns.confirmed'))
          .setValueParser((value: any, item: Task) => !! item.confirmedAt)
          .setComponent(ConfirmedHeader)
          .setMeta({
            action: async (value: boolean, item: Task) => {
              await TaskService.toggleConfirm(this.company, item)
                .then((task: Task) => item.confirmedAt = task.confirmedAt)
            }
          })
      ])
      .setGroupBy('dateOrder', 'actualDate')
      .setGroupComponent(DayTotalHours)
      .setRowFilterInHeading(true)
      .setFilters([
        new DataTableFilter()
          .setOperator(FilterOperators.in)
          .setShowCondition(can([permissions.company.projects.index]))
          .setKeyIsPrimary()
          .setField(
            new SearchableField()
              .isMultiple()
              .setKey('project.uuid')
              .setTitle(__('company.views.projects.show.tabs.tasks-and-time.table.filters.projects'))
              .loadItems({ endpoint: `company/${ this.company.slug }/projects`, value: 'uuid', title: 'name', perPage: 20 })
              .setSize(FieldSizes.half)
          ),
        new DataTableFilter()
          .setOperator(FilterOperators.in)
          .setShowCondition(can([permissions.company.employees.index]))
          .setKeyIsPrimary()
          .setField(
            new SearchableField()
              .isMultiple()
              .setKey('responsible.uuid')
              .setTitle(__('company.views.projects.show.tabs.tasks-and-time.table.filters.employees'))
              .loadItems({ endpoint: `company/${ this.company.slug }/employees`, value: 'uuid', title: 'full_name', perPage: 20 })
              .setSize(FieldSizes.half)
          ),
        new DataTableFilter()
          .setOperator(FilterOperators.gte)
          .setField(
            new Field()
              .setType(FieldTypes.datePicker)
              .setTitle(__('company.views.projects.show.tabs.tasks-and-time.table.filters.from'))
              .setKey('actual_date')
              .setSize(FieldSizes.threeTwelfth)
          ),
        new DataTableFilter()
          .setOperator(FilterOperators.lte)
          .setField(
            new Field()
              .setType(FieldTypes.datePicker)
              .setTitle(__('company.views.projects.show.tabs.tasks-and-time.table.filters.to'))
              .setKey('actual_date')
              .setSize(FieldSizes.threeTwelfth)
          ),
        taskPeriodField(this.table)
      ])
      .setActions([
        // new ShowAction()
        //   .setAction(this.showTask),
        new DataTableAction()
          .setIcon('mdi-pencil')
          .setPermissions([ permissions.company.tasks.update ])
          .setTitle(__('company.views.projects.show.tabs.tasks-and-time.table.actions.edit'))
          .setAction(this.editTask),
        new DeleteAction()
          .setPermissions([ permissions.company.tasks.delete ])
          .setAction(this.deleteTask),
      ])
      .setColoredBorder((item: Task) => {
        if (item.confirmedAt) return 'task-confirmed'
        return 'task-default'
      })
  }

  addTask(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: this.user.role.level === RoleLevel.employee ? EmployeeAddTaskDialog : ManagerAddTaskDialog,
      persistent: true,
      maxWidth: 980,
      meta: {
        onSuccess: () => {
          const table: any = this.$refs.table
          table.refresh()
        }
      }
    })
  }

  showTask(task: Task): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: this.user.role.level === RoleLevel.employee ? EmployeeShowTaskDialog : ManagerShowTaskDialog,
      maxWidth: 980,
      meta: {
        task,
      }
    })
  }

  editTask(task: Task): void {
    if (! can([permissions.company.tasks.update], false, task)) return

    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: this.user.role.level === RoleLevel.employee ? EmployeeEditTaskDialog : ManagerEditTaskDialog,
      persistent: true,
      maxWidth: 980,
      meta: {
        task,
        onSuccess: (entry: Task) => {
          this.table.updateItem(entry)
        }
      }
    })
  }

  deleteTask(task: Task): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.views.projects.show.tabs.tasks-and-time.table.actions.delete.title'),
        text: __('company.views.projects.show.tabs.tasks-and-time.table.actions.delete.text', { id: task.uniqueId }),
        onYes: () => TaskService.destroy(this.company, task)
          .then(() => {
            this.table.removeItem(task)
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('company.views.projects.show.tabs.tasks-and-time.table.actions.delete.on-success'),
            })
          })
      }
    })
  }

  get disabled(): boolean {
    return this.company.uuid === '2242f02c-af85-47f7-ad0b-31fc26e1b502'
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }
}
