




























import { Component, Prop, Vue } from 'vue-property-decorator'
import Form from '@/shared/components/form/Form.vue'
import __ from '@/shared/helpers/__'
import TaskTypeFilesDialog from '@/company/components/task-types/TaskTypeFilesDialog.vue'
import FormBase from '@/shared/classes/form/form-base'
import _ from 'lodash'
import ArrayField from '@/shared/classes/form/fields/array-field'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import AddMultiplePhotosButton from '@/company/components/task-types/AddMultiplePhotosButton.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table';
import Attachment from '@/shared/modules/attachment/attachment.model';
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header';

@Component({
  components: { TaskTypeFilesDialog, Form },
  methods: { __ }
})
export default class AddFilesDialog extends Vue {
  @Prop() form!: FormBase
  dialog: boolean = false

  fullKey = 'task_photos'

  photosForm: FormBase = new FormBase()
    .setFields([
      new ArrayField()
        .setKey('photos')
        .setTitle(__('company.components.task-types.task-type-files-dialog.form.photos'))
        .setChildren([
          new Field()
            .setType(FieldTypes.file)
            .setKey('file')
            .setTitle(__('company.components.task-types.task-type-files-dialog.form.file'))
            .setValue([])
            .setMeta({
              type: 'image',
              accept: 'image/*',
            })
            .setSize(FieldSizes.half),
          new Field()
            .setKey('description')
            .setTitle(__('company.components.task-types.task-type-files-dialog.form.description'))
            .setSize(FieldSizes.half)
        ])
        .setAdditionalActions([
          AddMultiplePhotosButton
        ])
        .setAddButton(() => false)
    ])
    .setOnSuccess((data: any) => {
      _.set(this.form.data, this.fullKey, data.photos.filter((photo: any) => {
        if (photo.file) {
          if (photo.file.length !== 0) return photo
        }
      }))

      this.onClose()
    })

  openDialog() {
    this.photosForm.setInitialValues({
      photos:
        _.get(this.form.data, this.fullKey) ? _.get(this.form.data, this.fullKey) :
          [
            {
              file: [],
              description: '',
            }
          ]
    })

    this.dialog = true
  }

  onClose() {
    this.dialog = false
  }

  table: DataTableBase = new DataTableBase()
      .setModel(Attachment)
      .setHasFilter(false)
      .setUpdateUrl(false)
      .setPaginationNotVisible(true)
      .setHeaders([
        new DataTableHeader()
            .setKey('name')
            .setText(__('company.components.tasks.edit-task-attachments.table.headers.name')),
        new DataTableHeader()
            .setKey('customProperties.description')
            .setText(__('company.components.tasks.edit-task-attachments.table.headers.description'))
            .setSortable(false),
        new DataTableHeader()
            .setKey('customProperties.author.full_name')
            .setText(__('company.components.tasks.edit-task-attachments.table.headers.author')),
        new DataTableHeader()
            .setKey('createdAt')
            .setText(__('company.components.tasks.edit-task-attachments.table.headers.uploaded-date'))
      ])

  get badgeCount(): number {
    return _.get(this.form.data, this.fullKey, []).length + this.table.data.length
    // return 60;
  }
}
