





import { Component, Watch } from 'vue-property-decorator'
import AbstractTableTopRight from '@/shared/classes/table/AbstractTableTopRight'
import DataTableTab from '@/shared/classes/components/data-table/data-table-tab'
import IDataTableDefaultFilter from '@/shared/interfaces/data-table/data-table-default-filter.interface'
import { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import __ from '@/shared/helpers/__'

@Component
export default class TaskConfirmedFilters extends AbstractTableTopRight {
  @Watch('activeTab') private onActiveTabChange() {
    const tableFilters: IDataTableDefaultFilter[] = this.table.defaultFilters.filter((filter: IDataTableDefaultFilter) => filter.key !== 'confirmed_at')

    if (this.activeTab > 0) {
      tableFilters.push({
        operator: FilterOperators.nulled,
        key: 'confirmed_at',
        value: this.activeTab === 2,
      })
    }

    this.table.setDefaultFilters(tableFilters)
    this.refresh()
  }

  activeTab: number = 0
  tabs: DataTableTab[] = [
    new DataTableTab().setKey('all').setTitle(__('company.components.tasks.task-confirmed-filters.all')),
    new DataTableTab().setKey('confirmed').setTitle(__('company.components.tasks.task-confirmed-filters.confirmed')),
    new DataTableTab().setKey('non_confirmed').setTitle(__('company.components.tasks.task-confirmed-filters.not-confirmed')),
  ]
}
