



















import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import _ from 'lodash'
import PageHeader from '@/admin/components/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Task from '@/shared/modules/task/task.model'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import FormGroup, { FormGroupTypes } from '@/shared/classes/form/group'
import ArrayField from '@/shared/classes/form/fields/array-field'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import ITaskResponse from '@/shared/modules/task/task-response.interface'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import TaskTypeFilesEditDialog from '@/company/components/task-types/TaskTypeFilesEditDialog.vue'
import TaskService from '@/shared/modules/task/task.service'
import { AxiosError } from 'axios'
import IResponse from '@/shared/interfaces/modules/response.interface'
import ListTaskAttachments from '@/company/components/tasks/attachments/ListTaskAttachments.vue'
import { getBase64 } from '@/shared/helpers/get-base64.helper'
import PlannedTimeFooter from '@/company/components/tasks/add/PlannedTimeFooter.vue'
import AddFilesDialog from '@/company/components/tasks/add/AddFilesDialog.vue';

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class ManagerEditTaskDialog extends Vue {
  @Prop() meta!: any
  static GROUP_DETAILS = 'details'
  static GROUP_PLANNED_TIME = 'planned-time'
  static GROUP_TASK_TYPES = 'task-types'
  static GROUP_ATTACHMENTS = 'attachments'
  static GROUP_MORE = 'more'
  static GROUP_COMMENTS = 'comments'
  static GROUP_ADDITIONAL = 'additional'
  static GROUP_MORE_ADDITIONAL = 'more-additional'

  form: FormBase | null = null
  loading: boolean = false

  created() {
    let fields = [
      new SearchableField()
        .setKey('responsible_uuid')
        .setEntryKey('responsibleUuid')
        .setTitle(__('company.components.tasks.add-task-dialog.form.worker'))
        .setGroup(ManagerEditTaskDialog.GROUP_DETAILS)
        .setDisabled((): boolean => {
          if (! this.form) return true
          return this.form.entry.timesAlreadyTracked
        })
        .loadItems({ endpoint: `company/${ this.company.slug }/employees`, value: 'uuid', title: 'full_name', perPage: 20 })
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.datePicker)
        .setKey('actual_date')
        .setEntryKey('actualDate')
        .setTitle(__('company.components.tasks.add-task-dialog.form.actual.date'))
        .setDisabled((): boolean => {
          if (! this.form) return true
          return this.form.entry.timesAlreadyTracked || this.form.entry.isTrackedApp
        })
        .setGroup(ManagerEditTaskDialog.GROUP_DETAILS)
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.number)
        .setKey('planned_hours')
        .setEntryKey('plannedHours')
        .setTitle(__('company.components.tasks.add-task-dialog.form.planned.hours'))
        .setGroup(ManagerEditTaskDialog.GROUP_PLANNED_TIME)
        .setSize(FieldSizes.quarter),
      new Field()
        .setType(FieldTypes.number)
        .setKey('planned_minutes')
        .setEntryKey('plannedMinutes')
        .setTitle(__('company.components.tasks.add-task-dialog.form.planned.minutes'))
        .setGroup(ManagerEditTaskDialog.GROUP_PLANNED_TIME)
        .setSize(FieldSizes.quarter),
      new Field()
        .setType(FieldTypes.number)
        .setKey('planned_quantity')
        .setEntryKey('plannedQuantity')
        .setTitle(__('company.components.tasks.add-task-dialog.form.planned.quantity'))
        .setGroup(ManagerEditTaskDialog.GROUP_PLANNED_TIME)
        .setSize(FieldSizes.quarter),
      new SearchableField()
        .setKey('planned_unit_uuid')
        .setEntryKey('plannedUnitUuid')
        .setTitle(__('company.components.tasks.add-task-dialog.form.planned.measure'))
        .loadItems({ endpoint: '/units', value: 'uuid', title: 'name', perPage: 20 })
        .setGroup(ManagerEditTaskDialog.GROUP_PLANNED_TIME)
        .setSize(FieldSizes.twoHalfTwelfth),
      new Field()
          .setType(FieldTypes.custom)
          .setKey('photos')
          .setComponent(AddFilesDialog)
          .setValue([])
          .setGroup(ManagerEditTaskDialog.GROUP_PLANNED_TIME)
          .setSize(FieldSizes.oneTwelfth),
      new ArrayField()
        .setKey('task_times')
        .setEntryKey('taskTimes')
        .setGroup(ManagerEditTaskDialog.GROUP_TASK_TYPES)
        .setDense(true)
        // .setAddButton((value: any, entry: Task) => ! entry.isTrackedApp)
        .setChildren([
          new Field()
            .setHidden(true)
            .setKey('uuid'),
          new Field()
            .setType(FieldTypes.checkbox)
            .setKey('is_other_type')
            .setEntryKey('isOtherType')
            .setTooltip(__('company.components.tasks.add-task-dialog.form.task-types.is-other-type'))
            .setSize(FieldSizes.oneTwelfth),
          new SearchableField()
            .setKey('task_type_uuid')
            .setEntryKey('taskTypeUuid')
            .setGetInitialObjectKey((key: string) => {
              key = key.replace('task_times', 'taskTimes')
              key = key.replace('task_type_uuid', 'taskType')

              return key
            })
            .setTitle(__('company.components.tasks.add-task-dialog.form.task-types.uuid'))
            .loadItems({ endpoint: `company/${ this.company.slug }/task-types`, value: 'uuid', title: 'name', perPage: 20, filters: [
                {
                  type: FilterOperators.nulled,
                  name: 'deleted_at',
                  value: true
                }
            ]})
            .setVisibleIf((values: any, index: number|null) =>
              ! _.get(values, `task_times.${ index }.is_other_type`, true)
            )
            .setSize(FieldSizes.half),
          new Field()
            .setType(FieldTypes.textArea)
            .setRows(2)
            .setKey('other_name')
            .setEntryKey('otherName')
            .setTitle(__('company.components.tasks.add-task-dialog.form.task-types.other-name'))
            .setVisibleIf((values: any, index: number|null) =>
              _.get(values, `task_times.${ index }.is_other_type`, true)
            )
            .setSize(FieldSizes.half),
          new Field()
            .setType(FieldTypes.timePicker)
            .setKey('start_time')
            .setEntryKey('startTime')
            .setTitle(__('company.components.tasks.add-task-dialog.form.task-types.start-time'))
            .setSize(FieldSizes.twoHalfTwelfth),
          new Field()
            .setType(FieldTypes.timePicker)
            .setKey('end_time')
            .setEntryKey('endTime')
            .setTitle(__('company.components.tasks.add-task-dialog.form.task-types.end-time'))
            .setMeta({
              minKey: (fullKey: string) => `${ fullKey }.start_time`
            })
            .setSize(FieldSizes.twoHalfTwelfth),
          new Field()
            .setType(FieldTypes.custom)
            .setKey('photos')
            .setComponent(TaskTypeFilesEditDialog)
            .setValue([])
            .setSize(FieldSizes.oneTwelfth),
          new Field()
            .setType(FieldTypes.checkbox)
            .setKey('extra')
            .setTitle(__('company.components.tasks.add-task-dialog.form.task-types.extra'))
            .setSize(FieldSizes.oneTwelfth)
        ]),
      new ArrayField()
        .setKey('comments')
        .setGroup(ManagerEditTaskDialog.GROUP_COMMENTS)
        .setDense(true)
        .setInitialLength(0)
        .setChildren([
          new Field()
            .setKey('text')
            .setTitle(__('company.components.tasks.add-task-dialog.form.comments.comment')),
        ]),
      new Field()
        .setType(FieldTypes.textArea)
        .setKey('notes')
        .setGroup(ManagerEditTaskDialog.GROUP_MORE_ADDITIONAL)
        .setTitle(__('company.components.tasks.add-task-dialog.form.comment'))
        .setSize(FieldSizes.full),
      new SelectField()
        .setKey('status_uuid')
        .setEntryKey('statusUuid')
        .setTitle(__('company.components.tasks.add-task-dialog.form.status'))
        .loadItems({ endpoint: `statuses/model/${ Task.ALIAS }`, value: 'uuid', title: 'name' })
        .setGroup(ManagerEditTaskDialog.GROUP_ADDITIONAL)
        .setSize(FieldSizes.fourTwelfth),
      new Field()
        .setType(FieldTypes.checkbox)
        .setKey('send_by_email')
        .setEntryKey('sendByEmail')
        .setMeta({
          class: 'd-flex align-center'
        })
        .setGroup(ManagerEditTaskDialog.GROUP_ADDITIONAL)
        .setSize(FieldSizes.eightTwelfth)
        .setTitle(__('company.components.tasks.add-task-dialog.form.send-by-email')),
    ]
    if (! this.meta.project) {
      fields.unshift(new SearchableField()
        .setKey('project_uuid')
        .setEntryKey('projectUuid')
        .setTitle(__('company.components.tasks.add-task-dialog.form.project'))
        .setGroup(ManagerEditTaskDialog.GROUP_DETAILS)
        .loadItems({ endpoint: `company/${ this.company.slug }/projects`, value: 'uuid', title: 'name', perPage: 20, withTrashed: true })
        .setDisabled((): boolean => {
          if (! this.form) return true
          return this.form.entry.isTrackedApp
        })
        .isRequired(),
      )
    }

    this.form = new FormBase()
      .setUuid(this.meta.task.uuid)
      .setEndpoint(`company/${ this.company.slug }/tasks`)
      .setMethod(HttpMethod.PUT)
      .setModel(Task)
      .setGroups([
        new FormGroup()
          .setKey(ManagerEditTaskDialog.GROUP_DETAILS),
        new FormGroup()
          .setKey(ManagerEditTaskDialog.GROUP_PLANNED_TIME)
          .setType(FormGroupTypes.accordion)
          .setTitle(__('company.components.tasks.add-task-dialog.form.groups.planned-time'))
          .setChildren([
            new FormGroup()
                .setComponent(PlannedTimeFooter)
          ]),
        new FormGroup()
          .setKey(ManagerEditTaskDialog.GROUP_TASK_TYPES)
          .setTitle(__('company.components.tasks.add-task-dialog.form.groups.task-types')),
        new FormGroup()
          .setKey(ManagerEditTaskDialog.GROUP_ATTACHMENTS)
          .setComponent(ListTaskAttachments),
        new FormGroup()
          .setType(FormGroupTypes.accordion)
          .setKey(ManagerEditTaskDialog.GROUP_MORE)
          .setTitle(__('company.components.tasks.add-task-dialog.form.groups.more'))
          .setChildren([
            new FormGroup()
              .setKey(ManagerEditTaskDialog.GROUP_COMMENTS)
              .setTitle(__('company.components.tasks.add-task-dialog.form.groups.comments')),
            new FormGroup()
              .setKey(ManagerEditTaskDialog.GROUP_MORE_ADDITIONAL),
          ]),
        new FormGroup().setKey(ManagerEditTaskDialog.GROUP_ADDITIONAL),
      ])
      .setFields(fields)
      .setInjectValues({
        project_uuid: _.get(this.meta, 'project.uuid', null),
      })
      .setSubmit(false)
  }

  async submit() {
    this.loading = true

    const form: any = _.cloneDeep(this.form)

    await Promise.all(form.data.task_times.map(async (taskTime: any, taskTimeIndex: number) => {
      await Promise.all(taskTime.photos.map(async (photo: any, photoIndex: number) => {
        const base64 = await getBase64(photo.file)
        _.set(form, `data.task_times.${ taskTimeIndex }.photos.${ photoIndex }.file_name`, photo.file.name)
        _.set(form, `data.task_times.${ taskTimeIndex }.photos.${ photoIndex }.file`, base64)
      }))
    }))

    const data = { ...form.injectValues, ...form.data }


    const onUpdateSuccess = (responseTask: Task | void) => {
      this.loading = false
      this.$store.dispatch(GlobalActions.showSnackBar, {
        type: SnackBarTypes.success,
        message: __('company.components.tasks.edit-task-dialog.form.on-success')
      })
      this.$store.dispatch(GlobalActions.closeDialog)
      this.meta.onSuccess && this.meta.onSuccess(responseTask)
    }
    const updateTask = async (): Promise<Task | void> => {
      return TaskService.update(this.company, form?.entry.uuid, data)
          .then((response: IResponse<ITaskResponse>) => new Task(response.data))
          .catch((error: AxiosError) => {
            this.form?.catchErrors(error)
            this.loading = false
          })
    }

    if (this.meta.task.confirmedAt) {
      // TODO remove unconfirm -> confirm flow. BE should fix budget recalculation after task entry update
      await TaskService.toggleConfirm(this.company, this.meta.task)
      const responseTaskAfterUpdate = await updateTask();
      const responseTask = await TaskService.toggleConfirm(this.company, this.meta.task)
      if (responseTaskAfterUpdate) responseTaskAfterUpdate.confirmedAt = responseTask.confirmedAt
      onUpdateSuccess(responseTaskAfterUpdate);
    }
    else {
      const responseTask = await updateTask()
      onUpdateSuccess(responseTask);
    }
  }

  async closeDialog() {
    await this.$store.dispatch(GlobalActions.closeDialog)
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
