























import { Component, Prop, Vue } from 'vue-property-decorator'
import DataTableBase from '../../../shared/classes/components/data-table/data-table'
import Field from '@/shared/classes/form/field'
import FormBase from '@/shared/classes/form/form-base'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import Attachment from '@/shared/modules/attachment/attachment.model'
import _ from 'lodash'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import __ from '@/shared/helpers/__'
import DataTableAction from '@/shared/classes/components/data-table/data-table-action'
import GroupService from '@/shared/modules/group/group.service'
import { downloadFile } from '@/shared/helpers/download-file.helper'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'

@Component({
  components: {
    DataTable
  },
  methods: { __ }
})
export default class TaskTypeFilesShowDialog extends Vue {
  @Prop() field!: Field
  @Prop() form!: FormBase
  @Prop() fullKey!: string
  table: DataTableBase = new DataTableBase()
    .setModel(Attachment)
    .setHasFilter(false)
    .setUpdateUrl(false)
    .setPaginationNotVisible(true)
    .setHeaders([
      new DataTableHeader()
        .setKey('name')
        .setText(__('company.components.tasks.edit-task-attachments.table.headers.name')),
      new DataTableHeader()
        .setKey('customProperties.description')
        .setText(__('company.components.tasks.edit-task-attachments.table.headers.description'))
        .setSortable(false),
      new DataTableHeader()
        .setKey('customProperties.author.full_name')
        .setText(__('company.components.tasks.edit-task-attachments.table.headers.author')),
      new DataTableHeader()
        .setKey('createdAt')
        .setText(__('company.components.tasks.edit-task-attachments.table.headers.uploaded-date'))
    ])
  .setActions([
    new DataTableAction()
      .setIcon('mdi-eye')
      .setAction((attachment: Attachment) => {
        const tab: any = window.open(attachment.url, '_blank')
        tab.focus()
      })
      .setTitle(__('company.components.tasks.edit-task-attachments.show')),
    new DataTableAction()
      .setIcon('mdi-download')
      .setAction((attachment: Attachment) => {
        GroupService.downloadAttachment(this.company, this.form.entry.projectUuid, attachment.groupUuid, attachment)
          .then((response: any) => downloadFile(response, attachment.name))
      })
      .setTitle(__('company.components.tasks.edit-task-attachments.download')),
  ])
  dialog: boolean = false

  created() {
    this.table.setData(this.photos)
  }

  openDialog() {
    this.dialog = true
  }

  onClose() {
    this.dialog = false
  }

  get photos(): Attachment[] {
    return _.get(this.form.data, this.fullKey, [])
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
