






import { Component } from 'vue-property-decorator'
import DataTableHeaderComponent from '@/shared/classes/table/data-table-header-component'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import TaskService from '@/shared/modules/task/task.service'
import Task from '@/shared/modules/task/task.model'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import __ from '@/shared/helpers/__'
import ITaskResponse from '@/shared/modules/task/task-response.interface'

@Component
export default class ConfirmedHeader extends DataTableHeaderComponent {
  value = false
  loading = false

  async onClick() {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.components.tasks.confirmed-header.action.title'),
        text: __('company.components.tasks.confirmed-header.action.text'),
        onYes: () => {
          this.loading = true
          TaskService.massConfirm(this.company, this.table.data.filter((item: Task) => ! item.confirmedAt).map((item: Task) => item.uuid))
            .then(async () => {
              await this.refresh()
              this.$store.dispatch(GlobalActions.showSnackBar, {
                type: SnackBarTypes.success,
                message: __('company.views.tasks.index.table.on-success'),
              })
            })
          .finally(() => this.loading = false)
        }
      }
    })
  }

  get isActive(): boolean {
    return this.table.data.every((item: Task) => !! item.confirmedAt)
  }

  get isDisabled(): boolean {
    return this.isActive || this.loading
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
