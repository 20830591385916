import Service from '@/shared/classes/service'
import Company from '@/shared/modules/company/company.model'
import Task from '@/shared/modules/task/task.model'
import Attachment from '@/shared/modules/attachment/attachment.model'
import IResponse from '@/shared/interfaces/modules/response.interface'
import ITaskResponse from '@/shared/modules/task/task-response.interface'
import IAttachmentResponse from '@/shared/modules/attachment/attachment-response.interface'

export default class TaskService extends Service {
  static prefix = 'tasks'

  static create(company: Company, data: any): Promise<any> {
    return this.post(`company/${ company.slug }/${ this.prefix }`, data)
  }

  static createValidation(company: Company, data: any): Promise<any> {
    return this.post(`company/${ company.slug }/${ this.prefix }/store-validation`, data)
  }

  static update(company: Company, taskUuid: string, data: any): Promise<any> {
    return this.put(`company/${ company.slug }/${ this.prefix }/${ taskUuid }`, data)
  }

  static uploadAttachments(company: Company, taskUuid: string, data: FormData): Promise<any> {
    return this.post(`company/${ company.slug }/${ this.prefix }/${ taskUuid }/upload`, data)
  }

  static deleteAttachment(company: Company, task: Task, attachment: Attachment): Promise<any> {
    return this.delete(`company/${ company.slug }/${ this.prefix }/${ task.uuid }/attachments/${ attachment.uuid }`)
  }

  static attachments(company: Company, task: Task): Promise<Attachment[]> {
    return this.get(`company/${ company.slug }/tasks/${ task.uuid }/attachments`)
      .then((response: IResponse<IAttachmentResponse>) => response.data.data.map((item: IAttachmentResponse) => new Attachment(item)))
  }

  static toggleConfirm(company: Company, task: Task): Promise<Task> {
    return this.post(`company/${ company.slug }/${ this.prefix }/${ task.uuid }/toggle-confirm`, {})
      .then((response: IResponse<ITaskResponse>) => new Task(response.data))
  }

  static destroy(company: Company, task: Task): Promise<any> {
    return this.delete(`company/${ company.slug }/${ this.prefix }/${ task.uuid }`)
  }

  static massConfirm(company: Company, taskUuids: string[]): Promise<any> {
    return this.post(`company/${ company.slug }/${ this.prefix }/mass-confirm`, { task_uuids: taskUuids })
  }

  static outsideProjectTimes(company: Company, task: Task): Promise<any> {
    return this.get(`company/${ company.slug }/${ this.prefix }/${ task.uuid }/outside-project-times`)
  }
}
