



import { Component, Prop, Vue } from 'vue-property-decorator'
import Attachment from '@/shared/modules/attachment/attachment.model'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import FormBase from '@/shared/classes/form/form-base'
import TaskService from '@/shared/modules/task/task.service'
import AttachmentsPreview from '@/company/components/attachments/AttachmentsPreview.vue'

@Component({
  components: { AttachmentsPreview }
})
export default class ListTaskAttachments extends Vue {
  @Prop() form!: FormBase
  attachments: Attachment[] = []

  async created() {
    this.attachments = await TaskService.attachments(this.company, this.form.entry)
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
