







































import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import Form from '@/shared/components/form/Form.vue'
import PageHeader from '@/admin/components/PageHeader.vue'
import FormBase from '@/shared/classes/form/form-base'
import Task from '@/shared/modules/task/task.model'
import FormGroup, { FormGroupTypes } from '@/shared/classes/form/group'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import ArrayField from '@/shared/classes/form/fields/array-field'
import _ from 'lodash'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import moment from 'moment'
import TaskService from '@/shared/modules/task/task.service'
import { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import { AxiosError } from 'axios'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import TaskTypeFilesDialog from '@/company/components/task-types/TaskTypeFilesDialog.vue'
import { getBase64 } from '@/shared/helpers/get-base64.helper'

@Component({
  components: { Form, PageHeader },
  methods: { __ },
})
export default class EmployeeAddTaskDialog extends Vue {
  @Prop() meta!: any
  static GROUP_DETAILS = 'details'
  static GROUP_TASK_TYPES = 'task-types'
  static GROUP_MORE = 'more'
  static GROUP_COMMENTS = 'comments'
  static GROUP_ADDITIONAL = 'additional'
  static GROUP_MORE_ADDITIONAL = 'more-additional'

  forms: FormBase[] = []
  loading: boolean = false
  saved: FormBase[] = []

  created() {
    this.forms.push(this.createForm())
  }

  async submit() {
    try {
      let forms = _.cloneDeep(this.forms)
      this.loading = true
      forms = forms.filter((form: FormBase) => !this.saved.some((item: FormBase) => item.formId === form.formId))

      let validationError: boolean = false
      await Promise.all(forms.map(async (form: FormBase, index: number) => {
        await Promise.all(form.data.task_times.map(async (taskTime: any, taskTimeIndex: number) => {
          if (!taskTime.photos) {
            taskTime.photos = []
          }

          await Promise.all(taskTime.photos.map(async (photo: any, photoIndex: number) => {
            const base64 = await getBase64(photo.file)
            _.set(forms, `${ index }.data.task_times.${ taskTimeIndex }.photos.${ photoIndex }.file_name`, photo.file.name)
            _.set(forms, `${ index }.data.task_times.${ taskTimeIndex }.photos.${ photoIndex }.file`, base64)
          }))
        }))
      }))

      await Promise.all(forms.map(async (form: FormBase) => {
        const currentForm = this.forms.find((item: FormBase) => item.formId === form.formId)
        if (!currentForm) return
        const data = { ...form.injectValues, ...form.data }

        await TaskService.createValidation(this.company, data)
          .catch((error: AxiosError) => {
            currentForm.catchErrors(error)
            validationError = true
          })
      }))

      if (validationError) return this.loading = false

      const asyncFunctions: any = []

      forms.map((form: FormBase) => asyncFunctions.push(async () => await this.createTask(form)))

      for (let i = 0; i <= asyncFunctions.length - 1; i++) {
        await asyncFunctions[i]()
      }

      this.loading = false
      this.meta.onSuccess && this.meta.onSuccess()

      this.closeDialog()
    } catch (e) {
      console.log(e)
    }
  }


  async createTask(form: FormBase) {
    const currentForm = this.forms.find((item: FormBase) => item.formId === form.formId)
    if (!currentForm) return
    const data = { ...form.injectValues, ...form.data }
    await TaskService.create(this.company, data)
      .then(() => this.saved.push(currentForm))
  }

  addItem() {
    const latestIndex = this.forms.length - 1
    this.forms.push(this.createForm({
      actual_date: _.get(this.forms, `${ latestIndex }.data.actual_date`),
    }))
  }

  deleteItem(index: number) {
    this.forms = this.forms.filter((form: FormBase, i: number) => i !== index)
  }

  createForm(initialValues: any = {}) {
    return new FormBase()
      .setEndpoint(`company/${ this.company.slug }/tasks`)
      .setModel(Task)
      .setGroups([
        new FormGroup()
          .setKey(EmployeeAddTaskDialog.GROUP_DETAILS),
        new FormGroup()
          .setKey(EmployeeAddTaskDialog.GROUP_TASK_TYPES)
          .setTitle(__('company.components.tasks.add-task-dialog.form.groups.task-types')),
        new FormGroup()
          .setType(FormGroupTypes.accordion)
          .setKey(EmployeeAddTaskDialog.GROUP_MORE)
          .setTitle(__('company.components.tasks.add-task-dialog.form.groups.more'))
          .setChildren([
            new FormGroup()
              .setKey(EmployeeAddTaskDialog.GROUP_COMMENTS)
              .setTitle(__('company.components.tasks.add-task-dialog.form.groups.comments')),
            new FormGroup()
              .setKey(EmployeeAddTaskDialog.GROUP_MORE_ADDITIONAL),
          ]),
        new FormGroup().setKey(EmployeeAddTaskDialog.GROUP_ADDITIONAL),
      ])
      .setFields(this.generateFields())
      .setInjectValues({
        project_uuid: _.get(this.meta, 'project.uuid', null),
        responsible_uuids: [ this.user.uuid ],
      })
      .setInitialValues({
        ...{
          status_uuid: Task.STATUS_FINISHED,
          actual_date: moment().format('YYYY-MM-DD'),
        },
        ...initialValues,
      })
      .setSubmit(false)
  }

  generateFields() {
    let fields = [
      new Field()
        .setType(FieldTypes.datePicker)
        .setKey('actual_date')
        .setTitle(__('company.components.tasks.add-task-dialog.form.actual.date'))
        .setMeta({
          min: moment().subtract(this.company.timeRegistrationAvailability, 'day').format('YYYY-MM-DD'),
        })
        .setGroup(EmployeeAddTaskDialog.GROUP_DETAILS),
      new ArrayField()
        .setKey('task_times')
        .setGroup(EmployeeAddTaskDialog.GROUP_TASK_TYPES)
        .setDense(true)
        .setChildren([
          new Field()
            .setType(FieldTypes.checkbox)
            .setKey('is_other_type')
            .setTooltip(__('company.components.tasks.add-task-dialog.form.task-types.is-other-type'))
            .setSize(FieldSizes.oneTwelfth),
          new SearchableField()
            .setKey('task_type_uuid')
            .setTitle(__('company.components.tasks.add-task-dialog.form.task-types.uuid'))
            .loadItems({
              endpoint: `company/${ this.company.slug }/task-types`,
              value: 'uuid',
              title: 'name',
              perPage: 20,
              filters: [
                {
                  type: FilterOperators.nulled,
                  name: 'deleted_at',
                  value: true,
                },
              ],
            })
            .setVisibleIf((values: any, index: number | null) =>
              !_.get(values, `task_times.${ index }.is_other_type`, true),
            )
            .setSize(FieldSizes.half),
          new Field()
            .setType(FieldTypes.textArea)
            .setRows(2)
            .setKey('other_name')
            .setTitle(__('company.components.tasks.add-task-dialog.form.task-types.other-name'))
            .setVisibleIf((values: any, index: number | null) =>
              _.get(values, `task_times.${ index }.is_other_type`, true),
            )
            .setSize(FieldSizes.half),
          new Field()
            .setType(FieldTypes.timePicker)
            .setKey('start_time')
            .setTitle(__('company.components.tasks.add-task-dialog.form.task-types.start-time'))
            .setSize(FieldSizes.twoHalfTwelfth),
          new Field()
            .setType(FieldTypes.timePicker)
            .setKey('end_time')
            .setTitle(__('company.components.tasks.add-task-dialog.form.task-types.end-time'))
            .setMeta({
              minKey: (fullKey: string) => `${ fullKey }.start_time`,
            })
            .setSize(FieldSizes.twoHalfTwelfth),
          new Field()
            .setType(FieldTypes.custom)
            .setKey('photos')
            .setComponent(TaskTypeFilesDialog)
            .setValue([])
            .setSize(FieldSizes.oneTwelfth),
          new Field()
            .setType(FieldTypes.checkbox)
            .setKey('extra')
            .setTitle(__('company.components.tasks.add-task-dialog.form.task-types.extra'))
            .setSize(FieldSizes.oneTwelfth),
        ]),
      new ArrayField()
        .setKey('comments')
        .setGroup(EmployeeAddTaskDialog.GROUP_COMMENTS)
        .setInitialLength(0)
        .setChildren([
          new Field()
            .setKey('text')
            .setTitle(__('company.components.tasks.add-task-dialog.form.comments.comment')),
        ]),
      new Field()
        .setType(FieldTypes.textArea)
        .setKey('notes')
        .setGroup(EmployeeAddTaskDialog.GROUP_MORE_ADDITIONAL)
        .setTitle(__('company.components.tasks.add-task-dialog.form.comment'))
        .setDisabled(true)
        .setSize(FieldSizes.full),
      new SelectField()
        .setKey('status_uuid')
        .setTitle(__('company.components.tasks.add-task-dialog.form.status'))
        .loadItems({ endpoint: `statuses/model/${ Task.ALIAS }`, value: 'uuid', title: 'name' })
        .setGroup(EmployeeAddTaskDialog.GROUP_ADDITIONAL)
        .setSize(FieldSizes.fourTwelfth),
      new Field()
        .setType(FieldTypes.checkbox)
        .setKey('send_by_email')
        .setMeta({
          class: 'd-flex align-center',
        })
        .setGroup(EmployeeAddTaskDialog.GROUP_ADDITIONAL)
        .setSize(FieldSizes.eightTwelfth)
        .setTitle(__('company.components.tasks.add-task-dialog.form.send-by-email')),
    ]

    if (!this.meta.project) {
      fields.unshift(
        new SearchableField()
          .setKey('project_uuid')
          .setEntryKey('projectUuid')
          .setTitle(__('company.components.tasks.add-task-dialog.form.project'))
          .setGroup(EmployeeAddTaskDialog.GROUP_DETAILS)
          .loadItems({
            endpoint: `company/${ this.company.slug }/projects`,
            value: 'uuid',
            title: 'name',
            perPage: 20,
            filters: [
              {
                type: FilterOperators.equals,
                name: 'status_uuid',
                value: 'project.active',
              },
            ],
          })
          .isRequired(),
      )
    }

    return fields
  }

  async closeDialog() {
    await this.$store.dispatch(GlobalActions.closeDialog)
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }
}
