






import { Component } from 'vue-property-decorator'
import AbstractGroupComponent from '@/shared/classes/table/AbstractGroupComponent'
import __ from '@/shared/helpers/__'
import moment from 'moment'
import { getVersion } from '@/shared/helpers/version.helper'

@Component({
  methods: { __ }
})
export default class DayTotalHours extends AbstractGroupComponent {
  get value(): number {
    let date: moment.Moment = moment(this.group, 'YYYY-MM-DD', true)

    if (! date.isValid()) {
      date = moment(this.group, 'DD-MM-YYYY')
    }

    if (getVersion() === 'no') {
      return this.table.meta.dates[date.format('DD-MM-YYYY')]
    }

    return this.table.meta.dates[date.format('YYYY-MM-DD')]
  }
}
