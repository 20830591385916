import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import TaskType from '@/shared/modules/task-type/task-type.model'
import Unit from '@/shared/modules/unit/unit.model'
import ITaskTimeResponse from '@/shared/modules/task-time/task-time-response.interface'
import Attachment from '@/shared/modules/attachment/attachment.model'

export default class TaskTime extends Model<IModelResponse> {
  isOtherType!: boolean
  taskTypeUuid!: string
  otherName!: string
  realName!: string
  startTime!: string
  endTime!: string
  quantity!: number
  unitUuid!: string
  extra!: boolean

  taskType!: TaskType
  unit!: Unit
  photos!: Attachment[]

  transform(data: ITaskTimeResponse) {
    super.transform(data)

    this.isOtherType = data.is_other_type
    this.taskTypeUuid = data.task_type_uuid
    this.otherName = data.other_name
    this.realName = data.real_name
    this.startTime = data.start_time
    this.endTime = data.end_time
    this.quantity = data.quantity
    this.unitUuid = data.unit_uuid
    this.extra = data.extra

    this.taskType = this.setRelation(TaskType, data.task_type)
    this.unit = this.setRelation(Unit, data.unit)
    this.photos = this.setRelation(Attachment, data.photos)
  }
}
