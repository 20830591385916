import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import { ITranslatable } from '@/shared/interfaces/classes/model.interfaces'
import IUnitResponse from '@/shared/modules/unit/unit-response.interface'

export default class Unit extends Model<IModelResponse> {
  name!: ITranslatable<string>|string

  transform(data: IUnitResponse) {
    super.transform(data)

    this.name = name
  }
}
