import Model from '@/shared/classes/model'
import IModelResponse from '@/shared/interfaces/modules/model-response.interface'
import ITaskResponse from '@/shared/modules/task/task-response.interface'
import Project from '@/shared/modules/project/models/project.model'
import User from '@/shared/modules/user/user.model'
import Status from '@/shared/modules/status/status.model'
import TaskTime from '@/shared/modules/task-time/task-time.model'

export default class Task extends Model<IModelResponse> {
  static ALIAS = 'task'
  static STATUS_NOT_STARTED = 'task.not-started'
  static STATUS_FINISHED = 'task.finished'
  static STATUS_CONFIRMED = 'task.confirmed'

  uniqueId!: number
  projectUuid!: string
  authorUuid!: string
  responsibleUuid!: string
  statusUuid!: string
  plannedDate!: string
  plannedHours!: number
  plannedMinutes!: number
  plannedQuantity!: number
  plannedUnitUuid!: string
  dateOrder!: string
  actualDate!: string
  actualStartTime!: string
  actualEndTime!: string
  duration!: string
  allDuration!: string
  withoutBreaks!: string
  withoutBreaksDecimal!: number
  comments!: any
  hasExtraTime!: boolean
  allExtraTime!: boolean
  extraHours!: number
  extraMinutes!: number
  extraDuration!: string
  extraTimeDescription!: string
  notes!: string
  sendByEmail!: boolean
  timesAlreadyTracked!: boolean
  startLocationInProject!: boolean | null
  endLocationInProject!: boolean | null
  timesNotInProject!: string[] | null
  timesGpsFailed!: string[] | null
  endLocationGpsFailed!: boolean | null
  isTrackedApp!: boolean
  confirmedAt!: string | null

  attachmentsCount!: number
  lastAttachment!: string|null

  project!: Project
  author!: User
  responsible!: User
  status!: Status
  taskTimes!: TaskTime[]

  transform(data: ITaskResponse) {
    super.transform(data)

    this.uniqueId = data.unique_id
    this.projectUuid = data.project_uuid
    this.authorUuid = data.author_uuid
    this.responsibleUuid = data.responsible_uuid
    this.statusUuid = data.status_uuid
    this.plannedDate = data.planned_date
    this.plannedHours = data.planned_hours
    this.plannedMinutes = data.planned_minutes
    this.plannedQuantity = data.planned_quantity
    this.plannedUnitUuid = data.planned_unit_uuid
    this.dateOrder = data.date_order
    this.actualDate = data.actual_date
    this.actualStartTime = data.actual_start_time
    this.actualEndTime = data.actual_end_time
    this.duration = data.duration
    this.allDuration = data.all_duration
    this.withoutBreaks = data.without_breaks
    this.withoutBreaksDecimal = Number(data.without_breaks_decimal)
    this.comments = data.comments
    this.hasExtraTime = data.has_extra_time
    this.allExtraTime = data.all_extra_time
    this.extraHours = data.extra_hours
    this.extraMinutes = data.extra_minutes
    this.extraTimeDescription = data.extra_time_description
    this.extraDuration = data.extra_duration
    this.notes = data.notes
    this.sendByEmail = data.send_by_email
    this.timesAlreadyTracked = data.times_already_tracked
    this.isTrackedApp = data.is_tracked_app
    this.startLocationInProject = data.start_location_in_project
    this.endLocationInProject = data.end_location_in_project
    this.timesNotInProject = data.times_not_in_project
    this.timesGpsFailed = data.times_gps_failed
    this.endLocationGpsFailed = data.end_location_gps_failed
    this.confirmedAt = data.confirmed_at

    this.attachmentsCount = data.attachments_count
    this.lastAttachment = data.last_attachment

    this.project = this.setRelation(Project, data.project)
    this.author = this.setRelation(User, data.author)
    this.responsible = this.setRelation(User, data.responsible)
    this.status = this.setRelation(Status, data.status)
    this.taskTimes = this.setRelation(TaskTime, data.task_times)
  }
}
